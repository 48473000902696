import React from "react"
import tw, { styled, css } from "twin.macro"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import "styled-components/macro"
import _ from "lodash"
import moment from "moment-timezone"
import { hasEmptyDeep, titleCase, hasPassed } from "../utils"
import { TZ } from "../constants"

import WidgetCard from "./WidgetCard"

const Content = styled.div`
  ${tw`flex justify-between items-center gap-4`}
`

const Team = styled.div`
  ${tw`flex gap-2`}
`
const SpacingDiv = styled.div`
  ${tw`w-0 sm:w-[50px]`}
`
const Name = styled.div`
  ${tw`hidden sm:flex font-bold items-center justify-center w-[70px] sm:w-[120px] whitespace-nowrap overflow-hidden text-ellipsis`}
`

const ScoreNumber = styled.div`
  ${tw`font-bold text-4xl`}
`

const TicketsContainer = styled.div`
  ${tw`mt-4 pt-4 border-t-[1px] border-gray-300 flex justify-center`}
`

const TicketsButton = styled.a`
  ${tw`
    rounded-3xl bg-[#EC1F27] hover:bg-red-700
    transition-[background-color] duration-200
    text-white font-semibold
    px-16 py-4 max-w-max 
  `}

  ${({ disabled }) => disabled && tw`pointer-events-none grayscale`}
`

const VersusWidget = ({
  score,
  homeTeam,
  awayTeam,
  date,
  showBuyTicket,
  ticketLink,
}) => {
  const momentDate = moment(date)
  const isHomeGame =
    _.get(homeTeam, "frontmatter.name", "").toLowerCase() === "dawgs"

  return (
    <WidgetCard>
      <Content>
        <Team>
          <SpacingDiv />
          <div>
            <GatsbyImage
              image={getImage(_.get(awayTeam, "frontmatter.logo"))}
              alt="logo"
            />
          </div>
          <Name>{titleCase(_.get(awayTeam, "frontmatter.name"))}</Name>
        </Team>
        {hasEmptyDeep(score) ? (
          <div tw="text-center">
            <div tw="font-bold">
              <div>{moment.tz(momentDate, TZ).format("MMMM D, YYYY")}</div>
              <div>{moment.tz(momentDate, TZ).format("h:mm A dddd")}</div>
            </div>
            <div tw="text-xs">
              <div>
                {titleCase(_.get(homeTeam, "frontmatter.location.fieldName"))}
              </div>
              <div>{_.get(homeTeam, "frontmatter.location.address")}</div>
            </div>
          </div>
        ) : (
          <>
            <ScoreNumber>{_.get(score, "away")}</ScoreNumber>
            <div tw="uppercase font-bold">Final</div>
            <ScoreNumber>{_.get(score, "home")}</ScoreNumber>
          </>
        )}
        <Team>
          <Name tw="font-bold">
            {titleCase(_.get(homeTeam, "frontmatter.name"))}
          </Name>
          <div>
            <GatsbyImage
              image={getImage(_.get(homeTeam, "frontmatter.logo"))}
              alt="logo"
            />
          </div>
          <SpacingDiv />
        </Team>
      </Content>
      {(isHomeGame || showBuyTicket) && hasEmptyDeep(score) && !hasPassed(momentDate) && (
        <TicketsContainer>
          <TicketsButton
            href={ticketLink}
            target="_blank"
            // disabled={hasPassed(momentDate)}
          >
            Buy Tickets
          </TicketsButton>
        </TicketsContainer>
      )}
    </WidgetCard>
  )
}

export default VersusWidget
