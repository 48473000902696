import React, { useMemo } from "react"
import tw, { styled, css } from "twin.macro"
import "styled-components/macro"
import _ from "lodash"
import WidgetCard from "./WidgetCard"
import { titleCase } from "../utils"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import ProfileSvg from "../../static/img/svg/profile.svg"

const Header = styled.div`
  ${tw`text-xl font-bold mb-5`}
`

const Body = styled.div`
  ${tw`flex gap-2`}
`

const Player = styled.div`
  ${tw`text-center flex-1`}
`

const PlayerInfo = styled.div`
  ${tw`font-semibold mt-1`}
`

const Stats = styled.div`
  ${tw`text-gray-500 text-sm`}
`

const AvatarLink = styled.span`
  ${tw`block mx-auto w-max`}
  height: 75px;

  > svg {
    ${tw`h-full fill-red-600`}
  }
`
const PlayerAvatarContainer = styled.div`
  ${tw`min-w-[50px] w-[50px] h-[50px] sm:min-w-[75px] sm:w-[75px] sm:h-[75px] rounded-full overflow-hidden`}
`

const TopPerformersWidget = ({ players, topPerformers }) => {
  const playersArr = useMemo(() => {
    let ret = []

    players.forEach(player => {
      const slug = _.get(player, "fields.slug")
      const topPerformer = _.find(topPerformers, tp =>
        slug.includes(_.get(tp, "player", ""))
      )

      const mergedPlayer = _.merge(
        {},
        _.get(player, "frontmatter"),
        topPerformer
      )
      ret.push(mergedPlayer)
    })

    return ret
  }, [players, topPerformers])

  return (
    <WidgetCard>
      <Header>Top Performers</Header>
      <Body>
        {!_.isEmpty(playersArr) &&
          playersArr.map(player => {
            const avatar = _.get(player, "avatar")
            const name = titleCase(_.get(player, "name", "")).trim()
            const upperStats = _.get(player, "upperStats", "")
            const lowerStats = _.get(player, "lowerStats", "")
            // const slug = _.get(player, "player", "")

            const [firstName, lastName] = name.split(" ")
            const formattedName = `${lastName}, ${Array.from(
              firstName
            )[0].toUpperCase()}`

            return (
              <Player>
                <AvatarLink>
                  {_.isNil(avatar) ? (
                    <ProfileSvg />
                  ) : (
                    <PlayerAvatarContainer>
                      <GatsbyImage
                        style={{ width: "100%", height: "100%" }}
                        imgStyle={{ width: "100%", height: "100%", borderRadius: "50%" }}
                        objectFit="cover"
                        objectPosition="50% 50%"
                        alt="avatar"
                        image={getImage(avatar)}
                      />
                    </PlayerAvatarContainer>
                  )}
                </AvatarLink>
                <PlayerInfo>
                  <span>{formattedName}</span>
                  <Stats>{upperStats}</Stats>
                  <Stats>{lowerStats}</Stats>
                </PlayerInfo>
              </Player>
            )
          })}
      </Body>
    </WidgetCard>
  )
}

export default TopPerformersWidget
